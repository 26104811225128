import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  qa: [],
  addqa: [],
  editqa: [],
  deleteqa: [],
};

const QASlice = createSlice({
  name: "QA",
  initialState,
  reducers: {
    QARequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.qa = [];
    },
    QASuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.qa = action.payload;
    },
    QAFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.qa = [];
    },

    addQARequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addqa = [];
    },
    addQASuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addqa = action.payload;
    },
    addQAFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addqa = [];
    },
    editQARequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editqa = [];
    },
    editQASuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editqa = action.payload;
    },
    editQAFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editqa = [];
    },
    deleteQARequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deleteqa = [];
    },
    deleteQASuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteqa = action.payload;
    },
    deleteQAFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deleteqa = [];
    },
    
  },
});
export const QAAction = QASlice.actions;
export default QASlice;
