import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  aboutus: [],
  addaboutus: [],
  editaboutus: [],
  deleteaboutus: [],
};

const AboutUsSlice = createSlice({
  name: "AboutUs",
  initialState,
  reducers: {
    AboutUsRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.aboutus = [];
    },
    AboutUsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.aboutus = action.payload;
    },
    AboutUsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.aboutus = [];
    },

    addAboutUsRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addaboutus = [];
    },
    addAboutUsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addaboutus = action.payload;
    },
    addAboutUsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addaboutus = [];
    },
    editAboutUsRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editaboutus = [];
    },
    editAboutUsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editaboutus = action.payload;
    },
    editAboutUsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editaboutus = [];
    },
    deleteAboutUsRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deleteaboutus = [];
    },
    deleteAboutUsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteaboutus = action.payload;
    },
    deleteAboutUsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deleteaboutus = [];
    },
    
  },
});
export const AboutUsAction = AboutUsSlice.actions;
export default AboutUsSlice;
