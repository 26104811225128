import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  cvs: [],

  loading1: false,
  error1: null,
  activity: [],

  loading2: false,
  error2: null,
  category: [],

  addactivity: [],

  editactivity: [],

  deleteactivity: [],

  addcategory: [],

  editcategory: [],

  deletecategory: [],
};

const CvSlice = createSlice({
  name: "Cv",
  initialState,
  reducers: {
    cvRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.cvs = [];
    },
    cvSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.cvs = action.payload;
    },
    cvFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.cvs = [];
    },
    ActivityRequest: (state, action) => {
      state.loading1 = true;
      state.error1 = null;
      state.activity = [];
    },
    ActivitySuccess: (state, action) => {
      state.loading1 = false;
      state.error1 = null;
      state.activity = action.payload;
    },
    ActivityFailure: (state, action) => {
      state.loading1 = false;
      state.error1 = action.payload;
      state.activity = [];
    },

    addactivityRequest: (state, action) => {
      state.error1 = null;
      state.addactivity = [];
    },
    addactivitySuccess: (state, action) => {
      state.error1 = null;
      state.addactivity = action.payload;
    },
    addactivityFailure: (state, action) => {
      state.error1 = action.payload;
      state.addactivity = [];
    },
    editactivityRequest: (state, action) => {
      state.error1 = null;
      state.editactivity = [];
    },
    editactivitySuccess: (state, action) => {
      state.error1 = null;
      state.editactivity = action.payload;
    },
    editactivityFailure: (state, action) => {
      state.error1 = action.payload;
      state.editactivity = [];
    },
    deleteactivityRequest: (state, action) => {
      state.error1 = null;
      state.deleteactivity = [];
    },
    deleteactivitySuccess: (state, action) => {
      state.error1 = null;
      state.deleteactivity = action.payload;
    },
    deleteactivityFailure: (state, action) => {
      state.error1 = action.payload;
      state.deleteactivity = [];
    },
    categoryRequest: (state, action) => {
      state.loading2 = true;
      state.error2 = null;
      state.category = [];
    },
    categorySuccess: (state, action) => {
      state.loading2 = false;
      state.error2 = null;
      state.category = action.payload;
    },
    categoryFailure: (state, action) => {
      state.loading2 = false;
      state.error2 = action.payload;
      state.category = [];
    },

    addcategoryRequest: (state, action) => {
      state.error2 = null;
      state.addcategory = [];
    },
    addcategorySuccess: (state, action) => {
      state.error2 = null;
      state.addcategory = action.payload;
    },
    addcategoryFailure: (state, action) => {
      state.loading2 = false;
      state.error2 = action.payload;
      state.addcategory = [];
    },

    editcategoryRequest: (state, action) => {
      state.error2 = null;
      state.editcategory = [];
    },
    editcategorySuccess: (state, action) => {
      state.error2 = null;
      state.editcategory = action.payload;
    },

    editcategoryFailure: (state, action) => {
      state.error2 = action.payload;
      state.editcategory = [];
    },
    deletecategoryRequest: (state, action) => {
      state.error2 = null;
      state.deletecategory = [];
    },
    deletecategorySuccess: (state, action) => {
      state.error2 = null;
      state.deletecategory = action.payload;
    },

    deletecategoryFailure: (state, action) => {
      state.error2 = action.payload;
      state.deletecategory = [];
    },
  },
});
export const CvAction = CvSlice.actions;
export default CvSlice;
