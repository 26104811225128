import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  login: [],
  signoutt: [],
  changepassword: [],
  loading12: false,
  error12: null,
};

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    loginRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.login = [];
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.login = action.payload;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.login = [];
    },
    signoutRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.signoutt = [];
    },
    signoutSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.signoutt = action.payload;
    },
    signoutFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.signoutt = [];
    },
    changepasswordRequest: (state, action) => {
      state.loading12 = true;
      state.error12 = null;
      state.changepassword = [];
    },
    changepasswordSuccess: (state, action) => {
      state.loading12 = false;
      state.error12 = null;
      state.changepassword = action.payload;
    },
    changepasswordFailure: (state, action) => {
      state.loading12 = false;
      state.error12 = action.payload;
      state.changepassword = [];
    },
  },
});
export const AuthAction = AuthSlice.actions;
export default AuthSlice;
