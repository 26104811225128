import { combineReducers } from "redux";
//user

import AuthSlice from "./auth/authReducer";
import UserSlice from "./User/userReducer";
import CompanySlice from "./company/CompanyReducer";
import JobSlice from "./Job/JobReducer";
import CvSlice from "./Cv/CvReducer";
import ContactUsSlice from "./ContactUs/ContactUsReducer"
import AboutUsSlice from "./AboutUs/AboutUsReducer"
import QASlice from "./Q&A/Q&AReducer";
const rootReducer = combineReducers({
  authReducer: AuthSlice.reducer,
  userReducer: UserSlice.reducer,
  companyReducer: CompanySlice.reducer,
  jobReducer: JobSlice.reducer,
  cvReducer: CvSlice.reducer,
  contactUsReducer:ContactUsSlice.reducer,
  QAReducer:QASlice.reducer,
  AboutUsReducer:AboutUsSlice.reducer


});

export default rootReducer;
