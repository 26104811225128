import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Login = lazy(() => import("../Pages/Login/Login.js"));
const User = lazy(() => import("../Pages/user/user.jsx"));
const Company = lazy(() => import("../Pages/Company/Company.jsx"));
const Job = lazy(() => import("../Pages/Job/Job.jsx"));
const Cv = lazy(() => import("../Pages/Cv/Cv.jsx"));
const Activity = lazy(() => import("../Pages/Activity/Activity.jsx"));
const Category = lazy(() => import("../Pages/Category/Category.jsx"));
const ContactUs = lazy(() => import("../Pages/ContactUs/ContactUs.js"));
const AboutUs = lazy(() => import("../Pages/AboutUs/AboutUs.js"));
const QA = lazy(() => import("../Pages/Q&A/Q&A.js"));

const Viewapply = lazy(() => import("../Pages/Viewapply/Viewapply.jsx"));
const EditCompany = lazy(() => import("../Pages/Company/EditCompany.jsx"));
const ViewCandidates = lazy(() => import("../Pages/Job/ViewCandidates.jsx"));
const Shortlisted = lazy(() => import("../Pages/Shortlisted/Shortlisted.jsx"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "/", // Default route, redirects to login
        element: <Navigate to="/login" />,
      },
      {
        path: "/user",
        element:
          localStorage.getItem("role") === "1" ? (
            <User />
          ) : (
            <Navigate to="/job" />
          ),
      },
      {
        path: "/company",
        element:
          localStorage.getItem("role") === "1" ? (
            <Company />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      { path: "/job", element: <Job /> },
      {
        path: "/cv",
        element:
          localStorage.getItem("role") === "1" ? (
            <Cv />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      {
        path: "/category",
        element:
          localStorage.getItem("role") === "1" ? (
            <Category />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      {
        path: "/viewapply/:id",
        element:
          localStorage.getItem("role") === "1" ? (
            <Viewapply />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      { path: "/editcompany/:id", element: <EditCompany /> },
      { path: "/viewcandidate/:id", element: <ViewCandidates /> },
      {
        path: "/short",
        element:
          localStorage.getItem("role") === "1" ? (
            <Shortlisted />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      {
        path: "/activity",
        element:
          localStorage.getItem("role") === "1" ? (
            <Activity />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      {
        path: "/qa",
        element:
          localStorage.getItem("role") === "1" ? (
            <QA />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      {
        path: "/aboutus",
        element:
          localStorage.getItem("role") === "1" ? (
            <AboutUs />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      {
        path: "/contactus",
        element:
          localStorage.getItem("role") === "1" ? (
            <ContactUs />
          ) : (
            <Navigate to="/starter" />
          ),
      },
      { path: "/login", exact: true, element: <Login /> },
    ],
  },
];

export default ThemeRoutes;
