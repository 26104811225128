import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  company: [],
  loading133: false,
  error1: null,
  editcomapny: [],
  loading2: false,
  error2: null,
  onecomapny: [],
  district: [],
  loading3: false,
  error3: null,
};

const CompanySlice = createSlice({
  name: "Company",
  initialState,
  reducers: {
    CompanyRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.company = [];
    },
    CompanySuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.company = action.payload;
    },
    CompanyFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.company = [];
    },

    editCompanyRequest: (state, action) => {
      state.error1 = null;
      state.editcomapny = [];
    },
    editCompanySuccess: (state, action) => {
      state.error1 = null;
      state.editcomapny = action.payload;
    },
    editCompanyFailure: (state, action) => {
      state.error1 = action.payload;
      state.editcomapny = [];
    },
    oneCompanyRequest: (state, action) => {
      state.loading2 = true;
      state.error2 = null;
      state.onecomapny = [];
    },
    oneCompanySuccess: (state, action) => {
      state.loading2 = false;
      state.error2 = null;
      state.onecomapny = action.payload;
    },
    oneCompanyFailure: (state, action) => {
      state.loading2 = false;
      state.error2 = action.payload;
      state.onecomapny = [];
    },
    districtRequest: (state, action) => {
      state.loading3 = true;
      state.error3 = null;
      state.district = [];
    },
    districtSuccess: (state, action) => {
      state.loading3 = false;
      state.error3 = null;
      state.district = action.payload;
    },
    districtFailure: (state, action) => {
      state.loading3 = false;
      state.error3 = action.payload;
      state.district = [];
    },
  },
});
export const CompanyAction = CompanySlice.actions;
export default CompanySlice;
