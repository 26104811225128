import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  jobs: [],
  viewcandidate: [],
  onejob: [],
  editcandidate: [],
  editcandidate1: [],
  editcandidate2: [],
  deletejob: [],
  loading1: false,
  addjob: [],
  editjob: [],
  editreason: [],
  categorydata: [],

};

const JobSlice = createSlice({
  name: "Job",
  initialState,
  reducers: {
    JobRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.jobs = [];
    },
    JobSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.jobs = action.payload;
    },
    JobFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.jobs = [];
    },
    viewcandidateRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.viewcandidate = [];
    },
    viewcandidateSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.viewcandidate = action.payload;
    },
    viewcandidateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.viewcandidate = [];
    },
    onejobRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.onejob = [];
    },
    onejobSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.onejob = action.payload;
    },
    onejobFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.onejob = [];
    },
    editcandidateRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editcandidate = [];
    },
    editcandidateSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editcandidate = action.payload;
    },
    editcandidateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editcandidate = [];
    },
    editcandidate1Request: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editcandidate1 = [];
    },
    editcandidate1Success: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editcandidate1 = action.payload;
    },
    editcandidate1Failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editcandidate1 = [];
    },
    deletejobRequest: (state, action) => {
      state.loading1 = true;

      state.deletejob = [];
    },
    deletejobSuccess: (state, action) => {
      state.loading1 = false;

      state.deletejob = action.payload;
    },
    deletejobFailure: (state, action) => {
      state.loading1 = false;
      state.error = action.payload;
      state.deletejob = [];
    },

    editcandidate2Request: (state, action) => {
      state.loading1 = true;

      state.editcandidate2 = [];
    },
    editcandidate2Success: (state, action) => {
      state.loading1 = false;

      state.editcandidate2 = action.payload;
    },
    editcandidate2Failure: (state, action) => {
      state.loading1 = false;
      state.error = action.payload;
      state.editcandidate2 = [];
    },

    addjobRequest: (state, action) => {
      state.loading1 = true;

      state.addjob = [];
    },
    addjobSuccess: (state, action) => {
      state.loading1 = false;

      state.addjob = action.payload;
    },
    addjobFailure: (state, action) => {
      state.loading1 = false;
      state.error = action.payload;
      state.addjob = [];
    },

    editJobRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editjob = [];
    },
    editJobSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editjob = action.payload;
    },
    editJobFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editjob = [];
    },
    editreasonRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editreason = [];
    },
    editreasonSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editreason = action.payload;
    },
    editreasonFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editreason = [];
    },
   categorydataRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.categorydata = [];
    },
   categorydataSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.categorydata = action.payload;
    },
   categorydataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.categorydata = [];
    },





  },
});
export const JobAction = JobSlice.actions;
export default JobSlice;
