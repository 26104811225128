// ContactUsReducer.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  contactus: [],
  experience: [],
  expertise: [],
  education: [],
};

const ContactUsSlice = createSlice({
  name: "ContactUs",
  initialState,
  reducers: {
    ContactUsRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.contactus = [];
    },
    ContactUsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.contactus = action.payload;
    },
    ContactUsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.contactus = [];
    },
    ExperienceRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.experience = [];
    },
    ExperienceSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.experience = action.payload;
    },
    ExperienceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.experience = [];
    },
    ExpertiseRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.expertise = [];
    },
    ExpertiseSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.expertise = action.payload;
    },
    ExpertiseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.expertise = [];
    },
    EducationRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.education = [];
    },
    EducationSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.education = action.payload;
    },
    EducationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.education = [];
    },
  },
});

export const ContactUsAction = ContactUsSlice.actions;
export default ContactUsSlice;
