import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  users: [],
  loading1: false,
  error1: null,
  deleteuser: [],
  userviewmodal: [],
  loading2: false,
  error2: null,

  shortlisted: [],
  loading3: false,
  error3: null,
};

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    userRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.users = [];
    },
    userSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.users = action.payload;
    },
    userFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.users = [];
    },
    userdeleteRequest: (state, action) => {
      state.loading1 = true;
      state.error1 = null;
      state.deleteuser = [];
    },
    userdeleteSuccess: (state, action) => {
      state.loading1 = false;
      state.error1 = null;
      state.deleteuser = action.payload;
    },
    userdeleteFailure: (state, action) => {
      state.loading1 = false;
      state.error1 = action.payload;
      state.deleteuser = [];
    },
    userviewRequest: (state, action) => {
      state.loading2 = true;
      state.error2 = null;
      state.userviewmodal = [];
    },
    userviewSuccess: (state, action) => {
      state.loading2 = false;
      state.error2 = null;
      state.userviewmodal = action.payload;
    },
    userviewFailure: (state, action) => {
      state.loading2 = false;
      state.error2 = action.payload;
      state.userviewmodal = [];
    },

    shortlistedRequest: (state, action) => {
      state.loading3 = true;
      state.error3 = null;
      state.shortlisted = [];
    },
    shortlistedSuccess: (state, action) => {
      state.loading3 = false;
      state.error3 = null;
      state.shortlisted = action.payload;
    },
    shortlistedFailure: (state, action) => {
      state.loading3 = false;
      state.error3 = action.payload;
      state.shortlisted = [];
    },
  },
});
export const UserAction = UserSlice.actions;
export default UserSlice;
